import { Box } from '@mui/material'
import React from 'react'
import NavBanner from '../../components/NavBanner'
import EventsBanner from './EventsBanner'
import EventsCardSection from './EventsCardSection'
import EventsMobileBanner from './EventsMobileBanner'


const EventsPage = () => {
  return (
    <Box>
          <NavBanner/>
          <EventsBanner/>
          <EventsMobileBanner/>
          <EventsCardSection/>
    </Box>
  )
}

export default EventsPage
