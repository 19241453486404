import { Box } from '@mui/material'
import React from 'react'

const NavBanner = ({backgroundColor}) => {
  return (
    <Box sx={{width:"100%",
        height:{xs:"16vh",sm:"12rem"},
                backgroundColor: {backgroundColor},
        }} 
        >
          
         
    
        </Box>
  )
}

export default NavBanner
