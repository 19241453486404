import { Box, Typography } from '@mui/material'
import React from 'react'

const OurHistoryBanner = () => {
  return (
    <Box sx={{
        width:'100%',
        height:{xs:"20vh",sm:"22vh"},
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor:"#E8F5FF",

        
    }} >

    <Box sx={{
        width:"90%",
        height:"50%",
        textAlign:"center",
    }} >
    {/* <Typography sx={{textTransform:"uppercase",fontFamily:"Larose",fontSize:"30px",marginTop:{xs:"5%",sm:"3%"}}}>our history</Typography> */}

    </Box>
      
    </Box>
  )
}

export default OurHistoryBanner
