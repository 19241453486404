import React from 'react'
import LandingPage from './LandingPage'
import IndiaCollectionBanner from './IndiaCollectionBanner'
import Homemain from './Homemain'
import OurSpecialization from './OurSpecialization'



const Home = () => {
  return (
    <>
      <LandingPage/>
      <Homemain/>
      <OurSpecialization/>
      <IndiaCollectionBanner/>
    </>
  )
}

export default Home
