import React, { useEffect } from 'react'
import Earringsbanner from './Earringsbanner'
import { Box } from '@mui/material'
import NavBanner from '../../components/NavBanner'
import MoreCollections from '../getinspired/MoreCollections'
import EarringsNavigation from './EarringsNavigation'
import Earringsbgbanner from './Earringsbgbanner'
import EarringsSection from './EarringsSection'
import Earringbgmobilebanner from './Earringbgmobilebanner'
import EarringMoreCollection from './EarringMoreCollection'


const Earrings = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <Box>
<NavBanner backgroundColor="#E8f5ff" />

<Earringsbanner/>
<Earringsbgbanner/>
<Earringbgmobilebanner/>
<EarringsSection/>
<EarringsNavigation/>
<EarringMoreCollection/>
    </Box>
  )
}

export default Earrings
