import { Box, Typography } from '@mui/material'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ImgOne from '../../assets/Images/MoreCollection/Pendantsimage.png'
import ImgTwo from '../../assets/Images/MoreCollection/NecklaceImage.png'
import ImgThree from '../../assets/Images/MoreCollection/BangleAndBraceletes.png'
import ImgFour from '../../assets/Images/MoreCollection/EarringsImage.png'
import Rings from '../../assets/Images/MoreCollection/Rings.png'
import { Link } from 'react-router-dom'





const PendantMoreCollection = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"100rem",sm:"65vh"},
        display:"flex",
        justifyContent:'center',
        alignItems:"center",
        backgroundColor:"#E8f5ff",

        flexDirection:"column",
        borderTop:"1px solid rgba(0, 0, 0, 0.26)",
    }} >
    <Box sx={{
        width:"90%",
        marginTop:"2%",
        height:{xs:"5%",sm:"15%"},
    }} >
    <Typography variant='h4' sx={{fontFamily:"Larose"}} >More collections</Typography>

    </Box>
    <Box sx={{
        width:"90%",
       
        height:{xs:"80rem",sm:"70%"},
        backgroundColor:"#E8f5ff",

        display:"flex",
        flexDirection:{xs:"column",sm:'row'},
        justifyContent:"space-between",
    }} >
<Box sx={{
   width:{xs:"100%",sm:"24%"},
   height:{xs:"18rem",sm:"90%"},
    
}} >
<Link to='/getinspired'>
<Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={Rings}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
</Link>


   


</Box>
<Box sx={{
      width:{xs:"100%",sm:"24%"},
      height:{xs:"18rem",sm:"90%"},

}} >
<Link to="/bangles" >
<Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgThree}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
</Link>
 
   
</Box>
<Box sx={{
       width:{xs:"100%",sm:"24%"},
       height:{xs:"18rem",sm:"90%"},
    
}} >
<Link to="/earrings" >
<Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgFour}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
</Link>

   
</Box>
<Box sx={{
      width:{xs:"100%",sm:"24%"},

      height:{xs:"18rem",sm:"90%"},
    
}} >
<Link to="/necklace" >
<Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgTwo}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
</Link>

   

</Box>
    </Box>
      
    </Box>
  )
}

export default PendantMoreCollection
