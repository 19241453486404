import { Box, Typography } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EventImage from '../../assets/Images/EventsImage.png'
import React from 'react'
import { Translate } from '@mui/icons-material';


const EventsBanner = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: {sm:"18rem"},
        position: 'relative',
        overflow: 'hidden',
        display:{sm:"flex",xs:"none"},
      }}
    >
      <LazyLoadImage
        src={EventImage}
        alt="Indian Collection Two"
        effect="blur"
        width="100%"
        height="100%"
        style={{ objectFit: 'cover', transition: 'transform 0.5s ease-in-out' }}
        // className="banner-image"
      />
      <Box
        sx={{
          width: '10%',
          height: '10%',
          position: 'absolute',
          top: '50%',
          left:{xs:"40%",sm:"50%"},
          transform: 'translate(-50%, -50%)',

          display: 'flex',
          textAlign:"center",
        }}
      >
        <Typography variant="h3" sx={{color:"white",fontFamily:"Larose",textAlign:"center",textTransform:'uppercase'}} >
          Events
        </Typography>
      </Box>
      <style jsx>{`
        .banner-image:hover {
          transform: scale(1.1);
        }
      `}</style>
    </Box>
  )
}

export default EventsBanner
