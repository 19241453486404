import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ImageOne from '../../assets/HomePageBanner/IndiaCollectionImageOne.webp';
import ImageTwo from '../../assets/HomePageBanner/IndiaCollectionImageTwo.webp';



const IndiaCollectionBanner = () => {
  return (
    <Box sx={{
      width: "100%",
      // height:{xs:"125vh",sm:"100vh"},
      paddingTop:"10%",
      paddingBottom:{xs:"15%",sm:"5%"},
      display: "flex",
      justifyContent: "center",
      backgroundColor:"#E8F5FF",
      alignItems: "center"
    }} >
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection:{sm:"row",xs:"column"},
        alignItems: "center",
        width: "92%",
        height: "90%",
      }} >
        <Box sx={{
          width: {sm:"49%",xs:"100%"},
          height: "90%",
          display: "flex",
          justifyContent: "center",
           marginBottom:{sm:"0%",xs:"15%"},
          flexDirection:"column",
          alignItems: "center"
        }} >
          <LazyLoadImage
            src={ImageOne}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="95%"
            style={{ objectFit: 'cover' }}
          />
         <Box sx={{
            height:"10%",
            width:"100%",
            display:"flex",
            flexDirection:{sm:"row",xs:"column"},
            paddingTop:{xs:"0%",sm:"4%"},
            justifyContent:"space-between",
            alignItems:"center",
            textAlign:"center",
          }} >
          <Box sx={{
                        width:"100%",

                        textAlign:"center",

          }} >
            <Typography variant='h5'  sx={{textAlign:"center",fontFamily:"Larose",textTransform:"uppercase",color:"rgba(0, 51, 73, 1)"}} >Heritage Collection</Typography>
          </Box>
          {/* <Box  >
          <Button sx={{
    width:"200px",
    height:"36px",
    color:"black",
    fontSize:"12px",
    fontWeight:"500",
    fontFamily:"Epilogue",
    textTransform:"uppercase",
    borderRadius:"0",
    backgroundColor:"#F6FBFF",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
      backgroundColor:"white",
              boxShadow: 'none', // Remove shadow

            }
    
    }}>Know more</Button>
          </Box> */}

          </Box>
        </Box>
        <Box sx={{
          width: {sm:"49%",xs:"100%"},
          height: "90%",
          display: "flex",
          justifyContent: "center",
          flexDirection:"column",
          alignItems: "center"
        }} >
          <LazyLoadImage
            src={ImageTwo}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="95%"
            style={{ objectFit: 'cover' }}
          />
           <Box sx={{
            height:"10%",
            width:"100%",
            display:"flex",
            flexDirection:{sm:"row",xs:"column"},
            paddingTop:{xs:"0%",sm:"4%"},
            justifyContent:"space-between",
            alignItems:"center",
            textAlign:"center",
          }} >
          <Box sx={{
                        width:"100%",

                        textAlign:"center",

          }} >
            <Typography variant='h5'  sx={{            textAlign:"center",fontFamily:"Larose",textTransform:"uppercase",color:"rgba(0, 51, 73, 1)"}} >Heritage Collection</Typography>
          </Box>
          {/* <Box  >
          <Button sx={{
    width:"200px",
    height:"36px",
    color:"black",
    fontSize:"12px",
    fontWeight:"500",
    fontFamily:"Epilogue",
    textTransform:"uppercase",
    borderRadius:"0",
    backgroundColor:"#F6FBFF",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
      backgroundColor:"white",
              boxShadow: 'none', // Remove shadow

            }
    
    }}>Know more</Button>
          </Box> */}

          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default IndiaCollectionBanner;
