import { Box, Typography } from '@mui/material'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ImgOne from '../../assets/Images/Rectangle 105.png'
import ImgTwo from '../../assets/Images/Rectangle 108.png'
import ImgThree from '../../assets/Images/Rectangle 110.png'
import ImgFour from '../../assets/Images/Rectangle 111.png'
import ImgFive from '../../assets/Images/Rectangle 107.png'


const JwelleryPortfolio = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"320vh",sm:"92vh"},
        display:"flex",
        justifyContent:'center',
        alignItems:"center",
        backgroundColor:"#E8f5ff",

        flexDirection:"column",
        // borderTop:"1px solid rgba(0, 0, 0, 0.26)",
    }} >
    <Box sx={{
        width:"90%",
        marginTop:"5%",
        height:{xs:"5%",sm:"15%"},
        textTransform:"uppercase",

    }} >
    <Typography sx={{fontFamily:"Larose",fontSize:"28px"}} >Veekay : WHERE ART MEETS CRAFTSMANSHIP</Typography>

    </Box>
    <Box sx={{
        width:"90%",
        height:{xs:"90%",sm:"55%"},
        
        backgroundColor:"#E8f5ff",

        display:"flex",
        flexDirection:{xs:"column",sm:'row'},
        justifyContent:"space-between",
    }} >
<Box sx={{
   width:{xs:"100%",sm:"19%"},
    height:{xs:"18%",sm:"70%"},
    
    
}} >
 <Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgOne}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
    <Box sx={{width:"100%",height:"20%",marginTop:"5%"}}>
<Typography sx={{textAlign:"center",textTransform:"uppercase",fontFamily:"Epilogue",fontSize:"16px"}} >Open setting jewellery</Typography>
    </Box>

  

</Box>
<Box sx={{
   width:{xs:"100%",sm:"19%"},
   height:{xs:"18%",sm:"70%"},
    
}} >
 <Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgTwo}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
    </Box>
    <Box sx={{width:"100%",height:"20%",marginTop:"5%"}}>
<Typography sx={{textAlign:"center",textTransform:"uppercase",fontFamily:"Epilogue",fontSize:"16px"}} >Closed setting jewellery</Typography>
    </Box>
  

</Box>
<Box sx={{
   width:{xs:"100%",sm:"19%"},
   height:{xs:"18%",sm:"70%"},
    
}} >
 <Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgThree}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
    <Box sx={{width:"100%",height:"20%",marginTop:"5%"}}>
<Typography sx={{textAlign:"center",textTransform:"uppercase",fontFamily:"Epilogue",fontSize:"16px"}} >Heritage jewellery</Typography>
    </Box>

  

</Box>
<Box sx={{
   width:{xs:"100%",sm:"19%"},
   height:{xs:"18%",sm:"70%"},
    
}} >
 <Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgFour}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
    <Box sx={{width:"100%",height:"20%",marginTop:"5%"}}>
<Typography sx={{textAlign:"center",textTransform:"uppercase",fontFamily:"Epilogue",fontSize:"16px"}} >navratna jewellery</Typography>
    </Box>

  

</Box>
<Box sx={{
   width:{xs:"100%",sm:"19%"},
   height:{xs:"15%",sm:"70%"},
    
}} >
 <Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={ImgFive}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>
    <Box sx={{width:"100%",height:"20%",marginTop:"5%"}}>
<Typography sx={{textAlign:"center",textTransform:"uppercase",fontFamily:"Epilogue",fontSize:"16px"}} >EXQUISITE jewellery</Typography>
    </Box>

  

</Box>
    </Box>
      
    </Box>
  )
}

export default JwelleryPortfolio
