import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ImgOne from '../../assets/PendentPageImage/liens-still-life-encart-catalogue 1.png';
import ImgTwo from '../../assets/PendentPageImage/Group 18545.png';

import Ring1 from '../../assets/PendentPageImage/Group 18517.png';
import Ring2 from '../../assets/PendentPageImage/Group 18518.png';
import Ring3 from '../../assets/PendentPageImage/Group 18519.png';
import Ring4 from '../../assets/PendentPageImage/Group 18520.png';
import Ring5 from '../../assets/PendentPageImage/Group 18521.png';
import Ring6 from '../../assets/PendentPageImage/Group 18522.png';
import Ring7 from '../../assets/PendentPageImage/Group 18523.png';
import Ring8 from '../../assets/PendentPageImage/Group 18524.png';
import Ring9 from '../../assets/PendentPageImage/Group 18525.png';
import Ring10 from '../../assets/PendentPageImage/Group 18526.png';
import Ring11 from '../../assets/PendentPageImage/Group 18527.png';
import Ring12 from '../../assets/PendentPageImage/Group 18528.png';
import Ring13 from '../../assets/PendentPageImage/Group 18529.png';
import Ring14 from '../../assets/PendentPageImage/Group 18530.png';
import Ring15 from '../../assets/PendentPageImage/Group 18531.png';
import Ring16 from '../../assets/PendentPageImage/Group 18532.png';



const PendantsSection = () => {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleOpen = (image) => {
    setCurrentImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage('');
  };

  return (
    <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor:"#E8f5ff",

    }} >
      <Box sx={{
        marginTop: "5%",
        width: "90%",
        height: "60vh",
        display: 'flex',
        justifyContent: "space-between",
        flexWrap: "wrap",
      }} >
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring1)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring1}
              alt="Ring One"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring2)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring2}
              alt="Ring Two"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring3)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring3}
              alt="Ring Three"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring4)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring4}
              alt="Ring Four"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{
        marginTop: "5%",
        width: "90%",
        height: "60vh",
        display: 'flex',
        justifyContent: "space-between",
        flexWrap: "wrap",
      }} >
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring5)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring5}
              alt="Ring Five"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring6)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring6}
              alt="Ring Six"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "49.5%", md: "49.5%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
        //   onClick={() => handleOpen(ImgOne)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={ImgOne}
              alt="Inspiration One"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{
        marginTop: "1%",
        width: "90%",
        height: "60vh",
        display: 'flex',
        justifyContent: "space-between",
        flexWrap: "wrap",
      }} >
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring7)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring7}
              alt="Ring Seven"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring8)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring8}
              alt="Ring Eight"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring9)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring9}
              alt="Ring Nine"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring10)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring10}
              alt="Ring Ten"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{
        marginTop: "5%",
        width: "90%",
        height: "60vh",
        display: 'flex',
        justifyContent: "space-between",
        flexWrap: "wrap",
      }} >
       <Box
          sx={{
            width: { xs: "100%", sm: "49.5%", md: "49.5%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
        //   onClick={() => handleOpen(ImgTwo)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={ImgTwo}
              alt="Inspiration Two"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box> 
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring11)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring11}
              alt="Ring Thirteen"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring12)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring12}
              alt="Ring Fourteen"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{
        marginTop: "1%",
        width: "90%",
        height: "60vh",
        display: 'flex',
        justifyContent: "space-between",
        flexWrap: "wrap",
      }} >
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring13)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring13}
              alt="Ring Fifteen"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring14)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring14}
              alt="Ring Sixteen"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring15)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring15}
              alt="Ring Fifteen"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "48%", sm: "48%", md: "24%" },
            height: { xs: "48%", sm: "100%" },
            backgroundColor: "white",
          }}
          onClick={() => handleOpen(Ring16)}
        >
          <Box sx={{
            width: "100%",
            height: "100%",
          }} >
            <LazyLoadImage
              src={Ring16}
              alt="Ring Sixteen"
              effect="blur"
              width="100%"
              height="100%"
              style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
              className="image"
            />
          </Box>
        </Box>
        
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '80%', sm: '30%', md: '30%' },
            height:{xs:"60%",sm:"90%"},
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <img src={currentImage} alt="Selected ring" style={{ width: '100%', height: '100%' }} />
        </Box>
      </Modal>
    </Box>
  );
};

export default PendantsSection;
