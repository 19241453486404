import { Box, Typography } from '@mui/material'
import React from 'react'
import BigImage from '../../assets/HomePageBanner/Rectangle 91.png'
import SmImageOne from '../../assets/HomePageBanner/Rectangle 92.png'
import SmImageTwo from '../../assets/HomePageBanner/Rectangle 93.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const OurSpecialization = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"170vh",sm:"105vh",xxl:'90vh'},
        display:"flex",
        justifyContent:"center",
        paddingLeft:{xs:"4%",sm:"2%"},
        flexDirection:{xs:"column-reverse",sm:"row"},
        backgroundColor:"#E8F5FF",
        paddingRight:{xs:"4%",sm:"2%"},
    }} >
      <Box sx={{
        width:{xs:"100%",sm:"45%"},
        height:{xs:"50%",sm:"100%"},

        padding:{xs:"0%",sm:"0% 1% 1% 2%"},
      }} >
       <Box sx={{
        width:"100%",
        height:"100%",
        marginTop:{xs:"-20%",sm:"0%"},
      }} > 
       <LazyLoadImage
            src={BigImage}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />

      </Box>

      </Box>
      <Box sx={{
        
        height:{xs:"50%",sm:"100%"},
        width:{xs:"100%",sm:"55%"},

       
      }} >
      <Box sx={{
        width:"100%",
        height:"100%",
      
      }} >
      <Box sx={{
        width:"100%",
       
        height:{sm:"25%",xs:"30%"},
        display:"flex",
        justifyContent:{xs:"center",sm:"right"},
        // backgroundColor:"blue",

        
      }} >
      <Box sx={{
        width:{xs:"100%",sm:"80%"},
        
        marginBottom:{xs:"20px",sm:"0px"},

      }} >
      <Typography sx={{marginBottom:{xs:"5%",sm:"2%"},textTransform:"uppercase",  textAlign:{xs:"center",sm:"right"},fontFamily:"Larose",fontSize:{sm:"1.7rem",xs:"30px"},color:"rgba(0, 51, 73, 1)",}} >Our specialization</Typography>
      <Typography 
       
      
       sx={{
    
      color:'rgba(44, 44, 44, 1)',
      textAlign:{xs:"left",sm:"right"},
      fontWeight:"300",
      fontSize:"0.9rem",
      fontFamily:"Epilogue",
      }}>The process begins with a spark of inspiration. Skilled jewellers might sketch elaborate designs, create 3D models, or use traditional techniques like wax carving to bring their vision to life. The design considers the type of jewellery (ring, necklace, earrings etc.), </Typography>

      </Box>
      
      </Box>
      <Box sx={{
        width:"100%",
        height:{sm:"73%",xs:"50%"},
        // backgroundColor:"red",
        marginTop:{xs:"10%",sm:"0%"},
        display:"flex",
        justifyContent:"space-between",
        alignItems:"start",
      }} >
<Box  sx={{
        width:"49%",
        height:"100%",
       
        
      }} >
       <Box sx={{
        width:"100%",
        height:"100%",
      }} > 
       <LazyLoadImage
            src={SmImageOne}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />

      </Box>

</Box>
<Box  sx={{
        width:"49%",
        height:"100%",
       
        
      }} >
      <Box sx={{
        width:"100%",
        height:"100%",
      }} > 
       <LazyLoadImage
            src={SmImageTwo}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />

      </Box>

</Box>
      </Box>

      </Box>

      </Box>
    </Box>
  )
}

export default OurSpecialization
