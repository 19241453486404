import { Box, Typography } from '@mui/material'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ContactImage from '../../assets/MobileBanner/Events.png'


const EventsMobileBanner = () => {
  return (
    <Box
    sx={{
      width: '100%',
      height: '50vh',
      position: 'relative',
      overflow: 'hidden',
      display:{xs:"flex",sm:"none"},
    }}
  >
    <LazyLoadImage
      src={ContactImage}
      alt="Indian Collection Two"
      effect="blur"
      width="100%"
      height="100%"
      style={{ objectFit: 'cover', transition: 'transform 0.5s ease-in-out' }}
      className="banner-image"
    />
    {/* <Box
      sx={{
        width: '10%',
        height: '10%',
        position: 'absolute',
        top: '50%',
        left:{xs:"35%",sm:"50%"},
        transform: 'translate(-50%, -50%)',

        display: 'flex',
        textAlign:"center",
      }}
    >
      <Typography variant="h3" sx={{color:"white",fontFamily:"Larose",textAlign:"center",textTransform:"uppercase"}} >
      Events
      </Typography>
    </Box> */}
    <style jsx>{`
      .banner-image:hover {
        transform: scale(1.1);
      }
    `}</style>
  </Box>
  )
}

export default EventsMobileBanner
