import { Box, Typography } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GetInspiredBannerImage from '../../assets/Images/PendentBanner.png';

const Pendantsbgbanner = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '50vh',
        position: 'relative',
        overflow: 'hidden',
        display:{xs:"none",sm:"flex"},
      }}
    >
      <LazyLoadImage
        src={GetInspiredBannerImage}
        alt="Indian Collection Two"
        effect="blur"
        width="100%"
        height="100%"
        style={{ objectFit: 'cover', transition: 'transform 0.5s ease-in-out' }}
        // className="banner-image"
      />
     <Box
        sx={{
          width: '10%',
          height: '10%',
          position: 'absolute',
          top: {xs:"70%",sm:"55%"},
          display: 'flex',
          padding: '3%',
        }}
      >
        <Typography variant="h3" sx={{color:"white",fontFamily:"Larose",textTransform:"uppercase",letterSpacing:"8px"}} >
          Pendants
        </Typography>
      </Box>
      <style jsx>{`
        .banner-image:hover {
          transform: scale(1.1);
        }
      `}</style>
    </Box>
  );
};

export default Pendantsbgbanner;
