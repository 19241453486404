import { Box, Typography } from '@mui/material';
import React from 'react';
import VeekayLogo from '../assets/Logo/VeekayLogo.png';
import { Link, useLocation } from 'react-router-dom';

const WebNavbar = () => {
  const location = useLocation();

  const linkStyle = {
    textDecoration: "none",
    color: "rgba(45, 45, 45, 1)",
    borderBottom: "1.5px solid transparent",
    '&:hover': {
      borderBottom: "1.5px solid #003349", // Change color to red
    },
  };

  const activeLinkStyle = {
    textDecoration: "none",
    color: "rgba(45, 45, 45, 1)",
    borderBottom: "1.5px solid #003349",
    '&:hover': {
      borderBottom: "1.5px solid #003349", // Change color to red
    },
  };

  return (
    <Box sx={{
      width: "100%",
      height: {sm:"7.5rem"},
      position: "absolute",
      zIndex: 11,
      display: { sm: "flex", xs: "none" },
      flexDirection: "column",
      alignItems: "center",
      justifyContent:"center",
      backgroundColor: "rgba(255, 255, 255, 1)", 
      borderBottom:"1px solid #b4c2cc",     
    }}>
      <Box sx={{
        display:"flex",
        alignItems:"center",
        width:"90%",
        
      }} >
      <Box sx={{
                display:"flex",
                width:"50rem",
                alignItems:"center",
                height:"30%",
                justifyContent:"space-between"


      }} >
      <Box sx={{
        width:"15rem",
      }} >
      <Link to="/" >
      <img src={VeekayLogo} alt="" width="100%" />

      </Link>
      </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",width:"60%"}}>
        <Link 
          style={location.pathname === '/' ? activeLinkStyle : linkStyle} 
          to="/"
        >
          <Typography sx={{
             fontFamily: "Epilogue", 
          textTransform: "uppercase",
          fontSize: "15px", 
          fontWeight: "500",
          '&:hover': {
      borderBottom: "1.5px solid #003349", 
    },
           }}>
            Home
          </Typography>
        </Link>
        <Link 
          style={location.pathname === '/aboutus' ? activeLinkStyle : linkStyle} 
          to="/aboutus"
        >
          <Typography sx={{ 
            fontFamily: "Epilogue", 
          textTransform: "uppercase", 
          fontSize: "15px",
           fontWeight: "500",
           
           '&:hover': {
            borderBottom: "1.5px solid #003349", 
          },
            }}>
            About Us
          </Typography>
        </Link>
        <Link 
          style={location.pathname === '/getinspired' ? activeLinkStyle : linkStyle} 
          to="/getinspired"
        >
          <Typography sx={{
             fontFamily: "Epilogue",
              textTransform: "uppercase",
               fontSize: "15px", 
               fontWeight: "500" ,
               transition: "border-bottom 0.6s ease",
               '&:hover': {
      borderBottom: "1.5px solid #003349", // Change color to red
    },
          }}>
            Get Inspired
          </Typography>
        </Link>
        <Link 
          style={location.pathname === '/contactus' ? activeLinkStyle : linkStyle} 
          to="/contactus"
        >
          <Typography sx={{ fontFamily: "Epilogue", 
          textTransform: "uppercase", 
          fontSize: "15px",
           fontWeight: "500" ,
           transition: "border-bottom 0.6s ease",
           '&:hover': {
      borderBottom: "1.5px solid #003349", // Change color to red
    },
           
           }}>
            Contact Us
          </Typography>
        </Link>
        <Link 
          style={location.pathname === '/events' ? activeLinkStyle : linkStyle} 
          to="/events"
        >
          <Typography sx={{ 
            fontFamily: "Epilogue",
           textTransform: "uppercase", 
           fontSize: "15px", 
           fontWeight: "500" ,
           transition: "border-bottom 0.6s ease",
           '&:hover': {
      borderBottom: "1.5px solid #003349", // Change color to red
    },
           }}>
            Events
          </Typography>
        </Link>
      </Box>
      </Box>
     
      </Box>
    
    </Box>
  );
}

export default WebNavbar;
