import { Box, Typography } from '@mui/material'
import React from 'react'
import MobileIcon from '../../assets/Icons/MobileIcon.png'
import UserIcon from '../../assets/Icons/UserIcon.png'


const OurLocationBanner = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"190vh",sm:"100vh"},
        backgroundColor:"rgba(0, 39, 55, 1)",
        display:{xs:"flex",sm:"none"},
        flexDirection:"column",
        alignItems:"center",
        marginBottom:{xs:"15%",sm:"4%"},

    }} >
    <Box sx={{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"center",
        marginTop:{xs:"10%",sm:"2%"},
        paddingBottom:{xs:"8%",sm:"1%"},
        marginBottom:"2%",
        height:"10vh",
        borderBottom:"1px solid rgba(255, 255, 255, 0.26)",
    }} >
    <Typography sx={{color:"white",fontFamily:"Epilogue",textTransform:"uppercase",fontSize:{xs:"19px",sm:"20px"},fontWeight:"400",letterSpacing:"3px"}} variant='h4' >Our locations</Typography>
    </Box>

    <Box sx={{
      width:{xs:"100%",sm:"90%"},
      height:{xs:"100%",sm:"70%"},
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      flexWrap:"wrap",
      flexDirection:{xs:"column",sm:"row"},
      gap:"0",
    }} >

    <Box sx={{
      width:{xs:"100%",sm:"30%"},
      height:"205px",
      borderBottom:{xs:"1px solid rgba(255, 255, 255, 0.26)",sm:"1px solid rgba(255, 255, 255, 0.26)"},
      textAlign:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      color:"white"
    }} >
    <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
    <Typography sx={{fontFamily:"Epilogue",fontSize:"18px", fontWeight:"500",marginBottom:"3%"}} >VEEKAY JEWELS BV</Typography>
    <Typography  width={{width:"90%",fontFamily:"Epilogue",fontWeight:"300",fontSize:"16px",marginTop:"2px",marginBottom:"3px"}} >Hoveniersstraat 2, PO Box (BUS) 132, Office no. 1218 2018 Antwerp, Belgium</Typography>
    <Box sx={{
      display:"flex",
      width:"75%",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"2%",
    }} >
    <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      
     width:"50%",

    }} >
    <img src={UserIcon} alt="" width="16px" height="16px" />
    <Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}>Varun Doshi</Typography>
    </Box>
   <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      width:"50%",


    }} >
   <img src={MobileIcon} alt=""   width="16px" height="16px" />

<Typography  sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}  >  +32 (0)3 234 24 69</Typography>
   </Box>
    


    </Box>
    </Box>

    </Box>
    <Box sx={{
      width:{xs:"100%",sm:"30%"},
      height:"205px",
      borderBottom:{xs:"1px solid rgba(255, 255, 255, 0.26)",sm:"1px solid rgba(255, 255, 255, 0.26)"},
      borderLeft:{xs:"none",sm:"1px solid rgba(255, 255, 255, 0.26)"},
      textAlign:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      color:"white"
    }} >
    <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
    <Typography sx={{fontFamily:"Epilogue",fontSize:"18px", fontWeight:"500",marginBottom:"3%"}} >VEEKAY DIAMANTS</Typography>
    <Typography width={{width:"90%",fontFamily:"Epilogue",fontWeight:"300",fontSize:"16px",marginTop:"2px",marginBottom:"3px"}} >Bandra Kurla Complex,Bharat Diamond Bourse, DC4101, 4th Floor, Bandra East,Mumbai 400 051 (INDIA)</Typography>
    <Box sx={{
      display:"flex",
      width:"70%",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"2%",
    }} >
    <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      
     width:"50%",

    }} >
    <img src={UserIcon} alt="" width="16px" height="16px" />
    <Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}>Kaushal Doshi</Typography>
    </Box>
   <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      width:"50%",


    }} >
   <img src={MobileIcon} alt=""   width="16px" height="16px" />

<Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}} > 91 22 40565454</Typography>
   </Box>
    


    </Box>
    </Box>

    </Box>
    <Box sx={{
      width:{xs:"100%",sm:"30%"},
      height:"205px",
      borderBottom:{xs:"1px solid rgba(255, 255, 255, 0.26)",sm:"1px solid rgba(255, 255, 255, 0.26)"},
      borderLeft:{xs:"none",sm:"1px solid rgba(255, 255, 255, 0.26)"},
      textAlign:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      color:"white"
    }} >
    <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
    <Typography sx={{fontFamily:"Epilogue",fontSize:"18px", fontWeight:"500",marginBottom:"3%"}}>VEEKAY DIAMANTS PVT. LTD.</Typography>
    <Typography  width={{width:"90%",fontFamily:"Epilogue",fontWeight:"300",fontSize:"16px",marginTop:"2px",marginBottom:"3px"}} >Unit D1, Plot no15 Subplot F11& 12 Wicel, Midc Marol Central Road Andheri East Mumbai 400093 (India)</Typography>
    <Box sx={{
      display:"flex",
      width:"75%",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"2%",
    }} >
    <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      
     width:"50%",

    }} >
    <img src={UserIcon} alt="" width="16px" height="16px" />
    <Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}>Piyush Sanghavi</Typography>
    </Box>
   <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      width:"50%",


    }} >
   <img src={MobileIcon} alt=""   width="16px" height="16px" />

<Typography  sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}  > +91 22 49608131</Typography>
   </Box>
    


    </Box>
    </Box>

    </Box>
    <Box sx={{
      width:{xs:"100%",sm:"30%"},
      height:"205px",
      borderBottom:{xs:"1px solid rgba(255, 255, 255, 0.26)",sm:"none"},
      textAlign:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      color:"white"
    }} >
    <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
    <Typography sx={{fontFamily:"Epilogue",fontSize:"18px", fontWeight:"500",marginBottom:"3%"}}>KAMLESH DIAMOND CO. </Typography>
    <Typography   width={{width:"90%",fontFamily:"Epilogue",fontWeight:"300",fontSize:"16px",marginTop:"2px",marginBottom:"3px"}}   >Diamond House, Diamond Floor, #2, K.G Road, 3rd Cross, Bangalore – 560009 (INDIA)</Typography>
    <Box sx={{
      display:"flex",
      width:"70%",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"2%",
    }} >
    <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      
     width:"50%",

    }} >
    <img src={UserIcon} alt="" width="16px" height="16px" />
    <Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}>Kashyap Doshi</Typography>
    </Box>
   <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      width:"50%",


    }} >
   <img src={MobileIcon} alt=""   width="16px" height="16px" />

<Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}  >91 80 41222893</Typography>
   </Box>
    


    </Box>
    </Box>

    </Box>
    <Box sx={{
      width:{xs:"100%",sm:"30%"},
      height:"205px",
      borderBottom:{xs:"1px solid rgba(255, 255, 255, 0.26)",sm:"none"},
      borderLeft:{xs:"none",sm:"1px solid rgba(255, 255, 255, 0.26)"},

      textAlign:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      color:"white"
    }} >
    <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
    <Typography sx={{fontFamily:"Epilogue",fontSize:"18px", fontWeight:"500",marginBottom:"3%"}} >RAMANKANT & Co.</Typography>
    <Typography  width={{width:"90%",fontFamily:"Epilogue",fontWeight:"300",fontSize:"16px",marginTop:"2px",marginBottom:"3px"}}  >123, N.S.C Bose Road, Nathella Jubliee Plaza, Shop no. 3 & 4 Chennai – 600079, (INDIA)</Typography>
    <Box sx={{
      display:"flex",
      width:"60%",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"2%",
    }} >
    <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      
     width:"50%",

    }} >
    <img src={UserIcon} alt="" width="16px" height="16px" />
    <Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}>Anuj Doshi</Typography>
    </Box>
   <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      width:"50%",


    }} >
   <img src={MobileIcon} alt=""   width="16px" height="16px" />

<Typography  sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}} >91 44 42761121</Typography>
   </Box>
    


    </Box>
    </Box>

    </Box>
    <Box sx={{
      width:{xs:"90%",sm:"30%"},
      height:"205px",
      borderLeft:{xs:"none",sm:"1px solid rgba(255, 255, 255, 0.26)"},

      textAlign:"center",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      color:"white"
    }} >
    <Box sx={{textAlign:"center",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}} >
    <Typography sx={{fontFamily:"Epilogue",fontSize:"18px", fontWeight:"500",marginBottom:"3%"}} >UNITED JEWELLERY MFG. PVT. LTD.</Typography>
    <Typography width={{width:"90%",fontFamily:"Epilogue",fontWeight:"300",fontSize:"16px",marginTop:"2px",marginBottom:"3px"}}>302, Block no. 1, Seepz++, SEZ, MIDC, Andheri East, Mumbai – 400096 (INDIA)</Typography>
    <Box sx={{
      display:"flex",
      width:"82%",
      alignItems:"center",
      justifyContent:"space-between",
      marginTop:"2%",
    }} >
    <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      
     width:"50%",

    }} >
    <img src={UserIcon} alt="" width="16px" height="16px" />
    <Typography sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}> Olinda Claudius</Typography>
    </Box>
   <Box sx={{
      display:"flex",
      alignItems:"center",
      color:"rgba(255, 255, 255, 1)",
      width:"50%",


    }} >
   <img src={MobileIcon} alt=""   width="16px" height="16px" />

<Typography  sx={{color:"rgba(255, 255, 255, 1)",fontFamily:"Epilogue",fontSize:"15px",textTransform:"uppercase",fontWeight:"300",marginLeft:"5px"}}  > 91 22 8290991</Typography>
   </Box>
    


    </Box>
    </Box>

    </Box>
   
    
    
   
   
    
   
   
    

    </Box>
      
    </Box>
  )
}

export default OurLocationBanner
