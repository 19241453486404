import { Box, Typography } from '@mui/material'
import React from 'react'
import Image from '../../assets/Icons/ArrowIcon.png'
import { Link } from 'react-router-dom'



const BangleNavigation = () => {
  return (
    <Box sx={{
        width:"100%",
        height:"15vh",
        display:"flex",
        backgroundColor:"#E8f5ff",

        alignItems:"center",
        justifyContent:"center"
    }} >
    <Box sx={{
        width:"90%",
        height:"60%",
        display:"flex",
        alignItems:"center",
        justifyContent:{xs:"center",sm:"start"},
        
    }} >
    <Box sx={{display:"flex",width:{xs:"100%",sm:"40%"}, justifyContent:"space-between"}} >
    <Link to="/" style={{textDecoration:"none"}} >
    <Typography sx={{fontSize:"14px",textTransform:"uppercase",color:"rgba(109, 118, 152, 1)",fontFamily:"Epilogue",fontWeight:"500"}} >Home</Typography>

    </Link>
<img src={Image} alt="" width="6.3px" height="13px" />
<Typography sx={{fontSize:"14px",textTransform:"uppercase",color:"rgba(109, 118, 152, 1)",fontFamily:"Epilogue",fontWeight:"500"}}>Get Inspired</Typography>
<img src={Image} alt="" width="6.3px" height="13px" />
<Typography sx={{fontSize:"14px",textTransform:"uppercase",color:"rgba(50, 50, 50, 1)",fontFamily:"Epilogue",fontWeight:"500"}}>Bangles and bracelets</Typography>

    </Box>

    </Box>
      
    </Box>
  )
}

export default BangleNavigation
