import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CardImageOne from  '../../assets/EventsImage/EventsNewImage.JPG'
import CardImageTwo from  '../../assets/EventsImage/image 49.png'
import CardImageThree from  '../../assets/EventsImage/image 53.png'
import EventsBanner from '../../assets/Images/event banner.webp'
import EventsMovieBanner from '../../assets/Images/event bannermmobilea.webp'



const EventsCardSection = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"125vh",sm:"52rem",lg:"55rem",xl:'62rem',xxl:"80rem"},
        display:"flex",
        flexDirection:{xs:"column",sm:"row"},
        justifyContent:"center",
        alignItems:"center",
        gap:{xs:"2%",sm:"5%"},
    }} >

{/* <Box sx={{
    width:"90%",
    display:{xs:"flex",sm:"none"},
}} >
<Box sx={{
    width:"100%",
}} >
  <LazyLoadImage
      src={EventsMovieBanner}
      alt="Indian Collection Two"
      effect="blur"
      width="100%"
      height="100%"
      style={{ objectFit: 'cover', transition: 'transform 0.5s ease-in-out' }}
    //   className="banner-image"
    />
</Box>

</Box> */}

{/* <Box sx={{
    width:"90%",
    display:{xs:"none",sm:"flex"},
}} >
<Box sx={{
    width:"100%",
}} >
  <LazyLoadImage
      src={EventsBanner}
      alt="Indian Collection Two"
      effect="blur"
      width="100%"
      height="100%"
      style={{ objectFit: 'cover', transition: 'transform 0.5s ease-in-out' }}
    //   className="banner-image"
    />
</Box>

</Box> */}
    {/* <Box sx={{
       
        width:{xs:"85%",sm:"25%"},
        marginBottom:"2rem",
        // backgroundColor:"grey"
        border:"1px solid rgba(0, 0, 0, 0.16)",
    }} > */}
    {/* <Box sx={{
        width:"100%",
    }} >
 <LazyLoadImage
            src={CardImageOne}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
    </Box> */}
    {/* <Box sx={{
        width:"100%",
        backgroundColor:"white",
        textTransform:"uppercase",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
    }} >

    <Box sx={{
        textAlign:"center",
    }} >
        <Typography sx={{fontWeight:"500",fontSize:"18px",fontFamily:"Epilogue"}} >IIJS 2024</Typography>
        <Typography sx={{fontFamily:"Epilogue"}} >9 AUGUST 24 - 13 AUGUST 24 </Typography>
        {/* <Button sx={{
    width:"200px",
    height:"36px",
    color:"white",
    fontSize:"12px",
    fontFamily:"Epilogue",
    fontWeight:"400",
    borderRadius:"0",
    letterSpacing:"2px",
    textTransform:"uppercase",
    backgroundColor:"rgba(54, 54, 54, 1)",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
        backgroundColor:"rgba(54, 54, 54, 1)",
        boxShadow: 'none', // Remove shadow

            }
    
    }}>Register</Button> */}

    {/* </Box> */}

    {/* </Box> */} 

    {/* // </Box> */}
    {/* <Box sx={{
                width:{xs:"85%",sm:"25%"},

                height:{sm:"85%",xs:"30%"},

        border:"1px solid rgba(0, 0, 0, 0.16)",
    }} >
    <Box sx={{
        width:"100%",
        height:"85%",
    }} >
 <LazyLoadImage
            src={CardImageTwo}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
    </Box>
    <Box sx={{
        width:"100%",
        height:"15%",
        backgroundColor:"white",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
    }} >

    <Box sx={{
        textAlign:"center",
    }} >
        <Typography sx={{fontWeight:"500",fontSize:"18px",fontFamily:"Epilogue"}}>IIJS MUMBAI</Typography>
        <Typography sx={{fontFamily:"Epilogue"}} >12 March 24 - 20 march 24 </Typography>
        <Button sx={{
    width:"200px",
    height:"36px",
    color:"white",
    fontSize:"12px",
    fontWeight:"400",
    fontFamily:"Epilogue",
    borderRadius:"0",
    letterSpacing:"2px",

    textTransform:"uppercase",
    backgroundColor:"rgba(54, 54, 54, 1)",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
        backgroundColor:"rgba(54, 54, 54, 1)",
        boxShadow: 'none', // Remove shadow

            }
    
    }}>Register</Button>

    </Box>

    </Box>

    </Box>
    <Box sx={{
    width:{xs:"85%",sm:"25%"},

        
        height:{sm:"85%",xs:"30%"},
        border:"1px solid rgba(0, 0, 0, 0.16)",
    }} >
    <Box sx={{
        width:"100%",
        height:"85%",
    }} >
 <LazyLoadImage
            src={CardImageThree}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />
    </Box>
    <Box sx={{
        width:"100%",
        height:"15%",
        backgroundColor:"white",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
    }} >

    <Box sx={{
        textAlign:"center",
    }} >
        <Typography sx={{fontWeight:"500",fontSize:"18px",fontFamily:"Epilogue"}}>IIJS MUMBAI</Typography>
        <Typography sx={{fontFamily:"Epilogue"}} >12 March 24 - 20 march 24 </Typography>
        <Button sx={{
    width:"200px",
    height:"36px",
    color:"white",
    fontSize:"12px",
    fontWeight:"400",
    letterSpacing:"2px",

    fontFamily:"Epilogue",
    borderRadius:"0",
    textTransform:"uppercase",
    backgroundColor:"rgba(54, 54, 54, 1)",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
        backgroundColor:"rgba(54, 54, 54, 1)",
        boxShadow: 'none', // Remove shadow

            }
    
    }}>Register</Button>

    </Box>

    </Box>

    </Box>  */}
      
    </Box>
  )
}

export default EventsCardSection
