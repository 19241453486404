import React, { useRef } from 'react';
import './OriginatorBanner.css';
import BlueBanner from '../../assets/AboutusBanner/AboutusOriginatorBanner.png';

import Ramakant from '../../assets/AboutusBanner/Ramankant Doshi.png';
import Suryakant from '../../assets/AboutusBanner/Suryakant Doshi.png';
import Devndra from '../../assets/AboutusBanner/Devndra Doshi.png';
import Vinod from '../../assets/AboutusBanner/Vinod Doshi.png';
import Harendra from '../../assets/AboutusBanner/Harendra Doshi.png';
import Kamlesh from '../../assets/AboutusBanner/Kamlesh Doshi.png';
import Kaushal from '../../assets/AboutusBanner/Kaushal Doshi.png';
import Anuj from '../../assets/AboutusBanner/Anuj Doshi.png';
import kashyap from '../../assets/AboutusBanner/kashyap Doshi.png';
import Varun from '../../assets/AboutusBanner/Varun Doshi.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import 'swiper/swiper-bundle.css';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Typography } from '@mui/material';
// Import Swiper styles
import 'swiper/css/navigation';
// Import Navigation module separately
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Initialize Swiper core
SwiperCore.use([Navigation]);

function OriginatorBanner() {
  const swiperRef = useRef(null);

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Box sx={{ width: "100%", height: {xs:"135vh",sm:"135vh"}, position: "relative" }}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <LazyLoadImage
          src={BlueBanner}
          alt="Indian Collection Two"
          effect="blur"
          width="100%"
          height="100%"
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <div className='Homeportfolio-crousel'>
      <Box>
        <Typography sx={{
          textAlign:"center",
        marginTop:"2rem",
        color:"#FFFFFF",
        fontFamily:"larose",
        fontSize:"32px",
        }} >Our History</Typography>
      </Box>
        <div className='portfolio-heading'>
          <h1>The originators of veekay</h1>
          <Typography sx={{
            color: "white",
            marginTop: "2%",
            fontSize: {xs:"14px",sm:"15.5px"},
            width: {xs:"95%",sm:"80%"},
            textTransform: "uppercase",
          fontWeight:"200",
            fontFamily: "Epilogue"
          }}>
            At the heart of our jewellery company the visionary founders whose combined expertise and passion drive our brand's success. Their unique blend of creativity and strategic insight has shaped our identity and propelled us to the forefront of the jewellery industry.
          </Typography>
        </div>
        <div className='swiper-main'>
          <Swiper
            ref={swiperRef}
            spaceBetween={0}
            slidesPerView={3.4}
            // loop={true} // Enable infinite loop
            navigation={{
              nextEl: '.custom-next-button',
              prevEl: '.custom-prev-button',
            }}
            breakpoints={{
              // when window width is >= 0px
              0: {
                slidesPerView: 1,
                loop:true,
              },
              // when window width is >= 640px
              740: {
                slidesPerView: 1,
                loop:true,

              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 3.4,
                loop:false,

              }
            }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide className='swiper-slide-section'>
              <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>
                <img src={Ramakant} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
                 fontSize: "22px",
                 fontWeight:"400",
                  textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                  
                }}>Ramankant Doshi</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontWeight:"200",
                  fontSize: "14px",
                  textAlign: { xs: "center", sm: "left" }
                }}>At Chennai, In 1958, Shri RAMANKANT V DOSHI & Shri SURYAKANT DOSHI established the COMPANY focusing on Gemstones, Diamonds & Pearls.</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontWeight:"500",
                  fontSize: "18px",
                  marginTop:{xs:"1rem",sm:"2.4rem"},
                  marginBottom:{xs:"1rem",sm:"0rem"},
                  textAlign: { xs: "center", sm: "left" }
                }} >1958</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Suryakant} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
                   fontSize: "22px",
                   fontWeight:"400",
                  textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Suryakant Doshi</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontSize: "14px",
                  fontWeight:"200",

                  textAlign: { xs: "center", sm: "left" }
                }}>At Chennai, In 1958, Shri SURYAKANT DOSHI & Shri RAMANKANT V DOSHI established the COMPANY focusing on Gemstones, Diamonds & Pearls.</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Devndra} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
                  fontSize: "22px",
                  fontWeight:"400",
                  textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Devendra Doshi</Typography>
                <Typography sx={{
                  fontSize: "14px",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>In 1971, Devendra Doshi establishes KAMLESH DIAMONDS & CO in Bangalore, named after his youngest brother Kamlesh.</Typography>
                 <Typography sx={{
                  fontFamily: "Epilogue",
                  fontWeight:"500",
                  fontSize: "18px",
                  marginTop:{xs:"1rem",sm:"2.4rem"},
                  marginBottom:{xs:"1rem",sm:"0rem"},
                  textAlign: { xs: "center", sm: "left" }
                }} >1971</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Vinod} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
                  textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  fontSize: "22px",
                  fontWeight:"400",
                  textAlign: { xs: "center", sm: "left" }
                }}>Vinod Doshi</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontSize: "14px",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>In 1976, Vinod Doshi established a Mumbai office and factory, making it the diamond buying hub and jewelry manufacturing base for the entire group, both domestically and internationally.</Typography>
                 <Typography sx={{
                  fontFamily: "Epilogue",
                  fontWeight:"500",
                  fontSize: "18px",
                  marginTop:{xs:"1rem",sm:"0.7rem"},
                  marginBottom:{xs:"1rem",sm:"0rem"},
                  textAlign: { xs: "center", sm: "left" }
                }} >1976</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Harendra} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
                  fontSize: "22px",
                  fontWeight:"400",
                  textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Harendra Doshi</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontSize: "14px",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>In 1976, Shri HARENDRA DOSHI also joined his elder brother to support him at Chennai. Has a specialization in making Heritage and Navratna Jewellery. </Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Kamlesh} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
                   fontSize: "22px",
                   fontWeight:"400",
                  textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Kamlesh Doshi</Typography>
                <Typography sx={{
                  fontSize: "14px",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>In 1980, Kamlesh Doshi founded Varundiam Bvba in Antwerp, naming it after his son. He later partnered with Vinod to establish the International Factory, laying the foundation for VEEKAY's global expansion.</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontWeight:"500",
                  fontSize: "18px",
                  marginTop:{xs:"1rem",sm:"0.8rem"},
                  marginBottom:{xs:"1rem",sm:"0rem"},
                  textAlign: { xs: "center", sm: "left" }
                }} >1980</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Kaushal} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
 fontSize: "22px",
 fontWeight:"400",
                   textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Kaushal Doshi</Typography>
                <Typography sx={{
                  fontSize: "14px",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>Expert in manufacturing of Modern, Budgeted and Sophisticated Jewellery for the Indian & International Market. Purchase & Sales of loose diamonds for the entire organization.</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontWeight:"500",
                  fontSize: "18px",
                  marginTop:{xs:"1rem",sm:"0.8rem"},
                  marginBottom:{xs:"1rem",sm:"0rem"},
                  textAlign: { xs: "center", sm: "left" }
                }} >2024</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Anuj} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
 fontSize: "22px",
 fontWeight:"400",
                   textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Anuj Doshi</Typography>
                <Typography sx={{
                  fontSize: "14px",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>Specialization in trading & Sales of Polished Diamonds. Manufacture of Jewellery for the Chennai & South Indian Market.</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={kashyap} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
 fontSize: "22px",
 fontWeight:"400",
                   textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>kashyap Doshi</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontSize: "14px",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>Specialization in manufacturing of Complete Range of Traditional & Contemporary close set & Heritage Jewellery for the South Indian Market.</Typography>
              </Box>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide-section'>
            <Box sx={{ color: "white", width: {xs:"80%",sm:"80%"} }}>

                <img src={Varun} alt="" style={{ width: "100%" }} />
                <Typography variant='h6' sx={{
 fontSize: "22px",
 fontWeight:"400",
                   textTransform: "uppercase",
                  fontFamily: "Epilogue",
                  textAlign: { xs: "center", sm: "left" }
                }}>Varun Doshi</Typography>
                <Typography sx={{
                  fontFamily: "Epilogue",
                  fontSize: "14px",
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight:"200",

                }}>Specialization in European Jewelry and Design. International Market Sales. Sourcing of Diamonds and Colored Stones.</Typography>
              </Box>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="custom-navigation">
          <Link to="/portfolio" className='AllProjects-Portfoliobtn' style={{ textDecoration: "none" }}>
          </Link>
          <button className="custom-prev-button portfolio-btn-btn" onClick={goPrev}>
            <ArrowBackIosRoundedIcon />
          </button>
          <button className="custom-next-button" onClick={goNext}>
            <ArrowForwardIosRoundedIcon />
          </button>
        </div>
      </div>
    </Box>
  );
}

export default OriginatorBanner;
