import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../src/assets/Logo/MobileViewLogo.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';




const MobileNavbar = () => {
  return (
    <>
 <Box sx={{
     position: "absolute",
     zIndex: 11,
      width:"100%",
      height:"16vh",
     
      display:{sm:"none",xs:"flex"},

    }} >
    
    <Box sx={{
        width:"100%",
        height:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    }} >
    <Box sx={{
        width:"35%",
        height:"100%",
        // backgroundColor:"grey",
    }} >

    </Box>
    <Box sx={{
        width:"35%",
        height:"70%",
    }} >
    <Link  to="/" >
    <LazyLoadImage 
      src={Logo} 
      effect="blur"
          width="100%"
          height="100%"
       />
    </Link>
   
    </Box>

    <Box sx={{
        display:"flex",
        justifyContent:"end",
        alignItems:"center",
        width:"35%",
        height:"100%",
        // backgroundColor:"grey",
    }} >
    <SideMenu/>

    </Box>
    


    </Box>
    </Box>
    </>
   
  )
}

export default MobileNavbar
