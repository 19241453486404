import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import NavBanner from '../../components/NavBanner'
import RingBanner from './RingBanner'
import GetInspiredbanner from './GetInspiredbanner'
import RingSection from './RingSection'
import MoreCollections from './MoreCollections'
import GetinspiredNavigation from './GetinspiredNavigation'
import RingMobileBanner from './RingMobileBanner'

const GetInspired = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);
  return (
    <Box>
       <NavBanner backgroundColor="#E8f5ff" />
       <GetInspiredbanner/>
       <RingMobileBanner/>
       <RingBanner/>
       <RingSection/>
       <GetinspiredNavigation/>
       <MoreCollections/>
    </Box>
  )
}

export default GetInspired
