import { Box, Typography } from '@mui/material';
import React from 'react';
import LandingPageVideo from '../../assets/Videos/AboutUsVideo.webm';

const AboutLandingPage = () => {
  return (
    <Box sx={{ width: "100%", height: "100vh", position: 'relative', overflow: 'hidden' }}>
      
      <Box sx={{ width: "100%", height: '100vh', backgroundColor: "" }}>
        <video 
          src={LandingPageVideo} 
          autoPlay 
          loop 
          playsInline
          muted 
          style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
        />
      </Box>
      <Box sx={{
        position:"absolute",
        bottom:"0",
        zIndex:"10",
        width:"100%",
        backgroundColor: {xs:"rgba(0, 0, 0, 0.5)",sm:"rgba(0, 0, 0, 0.5)"}, // Adjust opacity here        
        height:{xs:"100%",sm:"100%"},
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
      }} >
<Box sx={{
  width:{xs:"90%",sm:"50%"},
  color:"white",
  textAlign:"center",
  marginTop:{xs:"0rem",sm:"5rem"},
}} >
  <Typography  sx={{fontFamily:"Epilogue",textTransform:"uppercase",letterSpacing:"3px"}}  >our history</Typography>
  <Typography variant='h3'  sx={{fontSize:"2.2rem", fontFamily:"Larose",textTransform:"uppercase",marginTop:"5%",marginBottom:"5%"}}>A FAMILY STORY</Typography>
  <Typography   sx={{fontFamily:"Epilogue",textTransform:"uppercase"}}  >In the transformative year of 1961, amidst the societal upheavals and cultural revolutions that defined the era, a singular visionary embarked on an audacious journey to reshape the diamond jewelry industry. This individual, driven by an unyielding passion and an insatiable desire to create something truly extraordinary, sought to build not merely a company but a lasting legacy that would shine brilliantly through the annals of time.</Typography>

</Box>
      </Box>
    </Box>
  );
}

export default AboutLandingPage;
