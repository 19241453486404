import { Box } from '@mui/material'
import React from 'react'
import BackgroundImage from '../../assets/HomePageBanner/bg.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import HomeTwoImages from './HomeTwoImages'
import OurNewest from './OurNewest'
import OucollectionSlider from './OucollectionSlider'
import LegacyBanner from './LegacyBanner'


const Homemain = () => {
  return (
    <Box sx={{
        position:"relative",
        height:{xs:"188rem",sm:"442vh",xxl:'320vh'},
        backgroundColor:"white",
    }} >
       <LazyLoadImage
            src={BackgroundImage}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />
          <HomeTwoImages/>
          <OucollectionSlider/>
          <LegacyBanner/>
          <OurNewest/>
          




    </Box>
  )
}

export default Homemain
