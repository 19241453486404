import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import ImageOne from '../../assets/HomePageBanner/LegecyRightImage.png'
import ImageTwo from '../../assets/HomePageBanner/RingLegecyImage.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import LegecyVideo from '../../assets/Videos/LegecyVideo.webm'
import { Link } from 'react-router-dom'


const LegacyBanner = () => {
  return (
    <Box sx={{
    width:"100%",
    height:{xs:"55rem",sm:"110vh",xxl:'85vh'},
    display:"flex",
    justifyContent:'center',
    alignItems:"center",
    position:"absolute",
    top:{xs:"56%",sm:"54%"},
    }}>
    <Box sx={{
        width:"90%",
        height:"100%",
        display:"flex",
        flexDirection:{xs:"column",sm:"row"},
    }} >

    <Box sx={{
        width:{xs:"100%",sm:"50%"},
    height:"100%"}} >

    <Box sx={{
        width:"100%",
        height:"30%",
        display:"flex",
        flexDirection:"column",
        alignItems:{xs:"center",sm:"start"}
    }} >
<Typography variant='h5' sx={{marginBottom:"3%",textTransform:"uppercase",fontFamily:"Larose",color:"rgba(0, 51, 73, 1)",textAlign:{xs:"center",sm:"start"},fontSize:{xs:"32px",sm:"1.8rem"}}} >the beginning of a legacy </Typography>
<Typography sx={{fontFamily:"Epilogue",width:"90%",fontSize:"1rem",color:"rgba(44, 44, 44, 1)",fontWeight:"300",marginBottom:"3%"}} >In 1961, a visionary individual set out with a dream to establish an entity that would revolutionize the diamond jewelry industry. Fueled by passion and determination, this individual sought to create more than just a company but rather a legacy that would endure through the ages.</Typography>

<Link to="/aboutus" style={{height:"40px",width:"100%"}} >
<Button sx={{
    width:{xs:"100%",sm:"35%"},
    height:"40px",
    color:"black",
    fontSize:"14px",
    textTransform:"uppercase",
    fontFamily:"Epilogue",
    marginTop:"2%",
    marginBottom:{xs:"10%",sm:"0%"},
    fontWeight:"400",
    borderRadius:"0",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
        backgroundColor:"transparent",
        boxShadow: 'none', // Remove shadow

            }
    
    }}>know full story</Button>
</Link>


    </Box>

    <Box sx={{
        width:"100%",
        height:{xs:"100%",sm:"70%"},       
        display:"flex",
        justifyContent:"end",
        alignItems:"end",
        paddingTop:{xs:"10%",sm:"20%"},
    }} >
 <Box sx={{width:"100%",height:"100%"}}>
          <video src={LegecyVideo}
          autoPlay 
          loop 
          playsInline
          muted 
           width="100%" height="100%"></video>
    </Box>
    </Box>

    </Box>
    <Box sx={{width:{xs:"100%",sm:"50%"},
    height:"100%",padding:"0% 1% 0% 1%"}} >
    <Box sx={{width:"100%",height:"100%",display:{xs:"none",sm:"flex"}}} >
    <LazyLoadImage
            src={ImageOne}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />
    </Box>

</Box>

    </Box>
      
    </Box>
  )
}

export default LegacyBanner
