import './App.css';
import Pagesroutes from './routes/routes';
function App() {
  return (
<>
<Pagesroutes/>
</>    
  );
}

export default App;