import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './homepage/Home';
import OutletComponent from '../components/OutletComponent';
import Contactus from './contactpage/Contactus';
import EventsPage from './events/EventsPage';
import Aboutus from './aboutuspage/Aboutus';
import GetInspired from './getinspired/GetInspired';
import Earrings from './earringspage/Earrings';
import Pendants from './pendantpage/Pendants';
import Necklace from './necklacepage/Necklace';
import Bangles from './banglesandbracelets/Bangles';


const Pagesroutes = () => {
  return (
    <Router>
    <Routes>
<Route element={<OutletComponent/>} >
<Route path="/" element={<Home />} />
<Route path="/contactus" element={<Contactus />} />
<Route path="/events" element={<EventsPage />} />
<Route path="/aboutus" element={<Aboutus />} />
<Route path='/getinspired' element={<GetInspired/>} />
<Route path='/earrings' element={<Earrings/>} />
<Route path='/pendants' element={<Pendants/>} />
<Route path='/necklace' element={<Necklace/>} />
<Route path='/bangles' element={<Bangles/>} />
</Route>
    </Routes>
    </Router>
  )
}

export default Pagesroutes
