import { Box } from '@mui/material';
import React from 'react';
import LandingPageVideo from '../../assets/Videos/LandingPageVideo.webm';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import BannerImage from '../../assets/HomePageBanner/HomeBannerImageBg.png'
import MobileBannerImage from '../../assets/HomePageBanner/MobileBannerBgImage.webp'
import MobileVideo from '../../assets/Videos/V-17 (1).webm'



const LandingPage = () => {
  return (
    <Box sx={{ width: "100%", height: {xs:"45rem",sm:"42rem",lg:"40rem",xl:"45rem",xxl:'50rem'}, position: 'relative', overflow: 'hidden' }}>    
      <Box sx={{ width: "100%",display:{xs:"flex",sm:"none"} }}>
        <LazyLoadImage
            src={MobileBannerImage}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />
      </Box>

      <Box sx={{ width: "100%",display:{xs:"none",sm:"flex"} }}>
        <video 
          src={LandingPageVideo} 
          autoPlay 
          loop 
          playsInline
          muted 
          style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
        />
        {/* <LazyLoadImage
            src={BannerImage}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          /> */}
      </Box>
    </Box>
  );
}

export default LandingPage;
