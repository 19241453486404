import { Box, Typography } from '@mui/material'
import React from 'react'
import VeekayLogo from '../assets/Logo/FooterLogo.png'
import FacebookIcon from '../assets/Icons/Facebook.png'
import InstrgramIcon from '../assets/Icons/Instagram.png'
import TwitterIcon from '../assets/Icons/Twitter.png'
import { Link } from 'react-router-dom'

const WebFooter = () => {
  return (
    <Box sx={{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        borderTop:"1px solid rgba(189, 189, 189, 1)",
        background: "linear-gradient(to left, #ABDCFF,#FCFDFF)", // Adjust the gradient as needed
    }} >
    <Box sx={{marginTop:{xs:"10%",sm:"3%"},marginBottom:{xs:"10%",sm:"3%"}}} >
    <img src={VeekayLogo} alt=""  width="160px"/>
    </Box>
    <Box sx={{
        display:"flex",
        width:{xs:"90%",sm:"60%"},
        justifyContent:"space-between",
        marginTop:{xs:"5%",sm:"0%"},
        marginBottom:{xs:"5%",sm:"0%"},
        flexWrap:"wrap"
    }} >
    <Box sx={{
      flexGrow:"1",

    }}  >
<Typography variant='h5' sx={{marginBottom:"5%",fontFamily:"Larose"}} >Quick Links</Typography>
<Link to="/aboutus" style={{textDecoration:"none",color:"black"}} >
<Typography sx={{fontFamily:"Epilogue",fontSize:{xs:"12.5px",sm:"16px"},marginBottom:{xs:"5%",sm:"0%"}}} >About Us</Typography>
</Link>
<Link to="/getinspired"style={{textDecoration:"none",color:"black"}}  >
<Typography  sx={{fontFamily:"Epilogue",fontSize:{xs:"12.5px",sm:"16px"},marginBottom:{xs:"5%",sm:"0%"}}} >Get Inspired</Typography>
</Link>
<Link to="/contactus" style={{textDecoration:"none",color:"black"}}  >
<Typography  sx={{fontFamily:"Epilogue",fontSize:{xs:"12.5px",sm:"16px"},marginBottom:{xs:"5%",sm:"0%"}}} >Contact Us</Typography>
</Link>
<Link to="/events" style={{textDecoration:"none",color:"black"}}  >
    <Typography  sx={{fontFamily:"Epilogue",fontSize:{xs:"12.5px",sm:"16px"},marginBottom:{xs:"5%",sm:"0%"}}} >Events</Typography>
    </Link>

    </Box>
    <Box sx={{
      flexGrow:"1"
    }}  >
    <Typography variant='h5' sx={{marginBottom:"5%",fontFamily:"Larose"}} >Other Pages</Typography>
    
    <Typography  sx={{fontFamily:"Epilogue",fontSize:{xs:"12.5px",sm:"16px"},marginBottom:{xs:"5%",sm:"0%"}}} >Sitemap</Typography>
    <Link to="/getinspired" style={{textDecoration:"none",color:"black"}}  >
    <Typography  sx={{fontFamily:"Epilogue",fontSize:{xs:"12.5px",sm:"16px"},marginBottom:{xs:"5%",sm:"0%"}}} >Catalogs</Typography>
    </Link>
    </Box>
    <Box sx={{
      flexGrow:"1",
      display:"flex",
      justifyContent:"center",
    
    }} >
    <Box>
    <Typography variant='h5' sx={{marginBottom:"5%",fontFamily:"Larose",marginTop:{xs:"10%",sm:"0%"}}} >Stay Up to Date</Typography>
    <Box sx={{width:"80%",alignItems:"center",marginTop:"5%", justifyContent:"space-between",display:"flex"}} >
    <Box>
    <img src={FacebookIcon} alt="" width="10px" />
    </Box>
    <Box>
    <a href="https://www.instagram.com/veekaydiamants/" target="_blank" >
    <img src={InstrgramIcon} alt="" width="20px"  />
    </a>
    </Box>
    <Box>
    <img src={TwitterIcon} alt="" width="20px" />
    </Box>
   

    </Box>

    </Box>

    </Box>

    </Box>
    <Box sx={{
      marginTop:{xs:"10%",sm:"3%"},
    marginBottom:{xs:"10%",sm:"2%"},textAlign:"center",
    display:"flex",
    justifyContent:"center",
    width:"100%",
    borderTop:"1px solid rgba(189, 189, 189, 1)",
    
    }} >
<Typography sx={{
  textAlign:"center",
  width:{xs:"70%",sm:"100%"},
  fontSize:{xs:"14px",sm:"15px"},
  fontFamily:"Epilogue",
  textTransform:"uppercase",
  fontWeight:"500",
  marginTop:"2%",

}} >copyright 2024 VEEKAY DIAMANTS. All rights reserved.</Typography>
    </Box>
      
    </Box>
  )
}

export default WebFooter
