import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import NavBanner from '../../components/NavBanner';
import PendantsBanner from './PendantsBanner';
import Pendantsbgbanner from './Pendantsbgbanner';
import PendantNavigation from './PendantNavigation';
import PendantsSection from './PendantsSection';
import PendantMoreCollection from './PendantMoreCollection';
import PendentMobileBanner from './PendentMobileBanner';




const Pendants = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <Box>
      <NavBanner backgroundColor="#E8f5ff" />
      <PendantsBanner/>
      <Pendantsbgbanner/>
      <PendentMobileBanner/>
      <PendantsSection/>
      <PendantNavigation/>
      <PendantMoreCollection/>

    </Box>
  )
}

export default Pendants
