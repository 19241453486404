import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import AboutLandingPage from './AboutLandingPage'
import ForeeverMarkBanner from './ForeeverMarkBanner'
import OriginatorBanner from './OriginatorBanner'
import AboutusHistory from './AboutusHistory'
import OurHistoryBanner from './OurHistoryBanner'
import JwelleryPortfolio from './JwelleryPortfolio'
import Originators from './Originators'
import AboutUsNavigation from './AboutUsNavigation'



const Aboutus = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <Box>
    <AboutLandingPage/>
    <OurHistoryBanner/>
    <AboutusHistory/>
    {/* <ForeeverMarkBanner/> */}
    <JwelleryPortfolio/>
    <OriginatorBanner/>
    <AboutUsNavigation/>
    {/* <Originators/> */}
    </Box>
  )
}

export default Aboutus
