import * as React from 'react';
import Box from '@mui/material/Box';
import OurHistoryIMAGEOne from '../../assets/AboutusBanner/OurHistoryImageOne.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Typography } from '@mui/material';


export default function AboutusHistory() {
  return (
    <Box sx={{ 
    width: '100%', 
    height: { xs: '100vh', sm: '90vh' },
     }}>

     <Box sx={{
      width:"100%",
      height:"100%",
      display:'flex',
      flexDirection:{xs:"column",sm:"row"},
     }} >
     <Box sx={{
      width:{xs:"100%",sm:"50%"},
      height:"100%",
      display:"flex",
      alignItems:"center",
      padding:"5%",
      backgroundColor:"white",
     }} >
     <Box>
     <Typography sx={{
  fontFamily:"Epilogue",
  fontSize:"22px",
  fontWeight:"400",
  color:"rgba(0, 0, 0, 1)",
}} >1961</Typography>
<Typography sx={{
  fontSize:{xs:"22px",sm:"32px"},
  textTransform:"uppercase",
  marginTop:"2%",
  marginTop:"1.2rem",
  fontFamily:"Larose",
  color:"rgba(0, 51, 73, 1)",


}} >THE beginning OF A LEGACY</Typography>
<Typography sx={{
  fontFamily:"Epilogue",
  textTransform:"uppercase",
  marginTop:"2%",
  width:{xs:"95%",sm:"90%"},
  fontSize:"18px",
  fontWeight:"300",
  marginTop:"1.2rem",

}} >In 1961 Ramankant V Doshi embarked on a journey with a dream to create an entity that would
 leave a mark in the diamond jewellery industry. 
 He started small in Chennai and got help from his brother Devendra Doshi,
 who set up Kamlesh Diamonds in Bangalore only 10 years later.</Typography>
     </Box>


     </Box>
     <Box sx={{
      width:{xs:"100%",sm:"50%"},
      height:"100%",
     }} >
     <Box sx={{
        width:"100%",
        height:"100%",
    }} >
     <LazyLoadImage
            src={OurHistoryIMAGEOne}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

    </Box>

     </Box>

     </Box>
     
    </Box>

  );
}