import React from 'react'
import { Outlet } from 'react-router-dom'
import WebNavbar from './WebNavbar'
import WebFooter from './WebFooter'
import MobileNavbar from './MobileNavbar'



const OutletComponent = () => {
  return (
    <div>
    <MobileNavbar/>
      <WebNavbar/>
      <Outlet/>
      <WebFooter/>
    </div>
  )
}

export default OutletComponent
