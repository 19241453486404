import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import { Box, Typography } from '@mui/material';
import ImageOne from '../../assets/HomePageBanner/Group 18533.png';
import ImageTwo from '../../assets/HomePageBanner/Group 18596.png';
import ImageThree from '../../assets/HomePageBanner/Group 18515.png';
import ImageFour from '../../assets/HomePageBanner/Group 18597.png';
import NecklaceImage from '../../assets/HomePageBanner/Group 18599.png'
import BannerImage from '../../assets/HomePageBanner/Group 18598.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const OucollectionSlider = () => {
    return (
        <>
            <Box sx={{ width: "100%", height:{sm:"100vh",lg:"100vh",xxl:'70vh'}, position: "absolute", top: {xs:"31%",sm:"30%"}, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Box sx={{ width: "90%", height: {xs:"8vh",sm:"12vh"} }}>
                    <Typography variant='h4' sx={{ fontFamily: "Larose",textAlign:{xs:"center",sm:"start"} }}>Our Collections</Typography>
                </Box>
                <Box sx={{ width: "90%", height: "75vh" }}>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        // loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            480: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                        <Link to = "/getinspired" style={{textDecoration:"none"}} >
                        <Box sx={{ width: "100%", height: "80%",backgroundColor:"white" }}>
                                <LazyLoadImage
                                    src={ImageOne}
                                    alt="Indian Collection One"
                                    effect="blur"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
                                    className="image"
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                textTransform:"uppercase",
                                textDecoration:"none",
                                textAlign:"start",
                                fontFamily:"Poppins",
                                marginTop:"6%",
                                color:"#003349",
                                fontWeight:"500",
                                }} >Rings</Typography>
                            </Box>
                        </Link>
                            
                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to="/pendants" style={{textDecoration:"none"}} >
                        <Box sx={{ width: "100%", height: "80%",backgroundColor:"white" }}>
                        <LazyLoadImage
                                    src={ImageTwo}
                                    alt="Indian Collection One"
                                    effect="blur"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
                                    className="image"
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                               textTransform:"uppercase",
                                textDecoration:"none",
                                textAlign:"start",
                                fontFamily:"Poppins",
                                marginTop:"6%",
                                color:"#003349",
                                color:"#003349",

                                fontWeight:"500",
                                }} >Pendants</Typography>
                            </Box>
                        </Link>
                       
                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to = "/necklace" style={{textDecoration:"none"}} >
                        <Box sx={{ width: "100%", height: "80%",backgroundColor:"white" }}>
                        <LazyLoadImage
                                    src={NecklaceImage}
                                    alt="Indian Collection One"
                                    effect="blur"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
                                    className="image"
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                              textTransform:"uppercase",
                                textDecoration:"none",
                                textAlign:"start",
                                fontFamily:"Poppins",
                                marginTop:"6%",
                                color:"#003349",

                                fontWeight:"500",
                                }} >Necklaces</Typography>
                            </Box>
                        </Link>
                      
                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to="/earrings"  style={{textDecoration:"none"}} >
                        <Box sx={{ width: "100%", height: "80%",backgroundColor:"white" }}>
                        <LazyLoadImage
                                    src={ImageFour}
                                    alt="Indian Collection One"
                                    effect="blur"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
                                    className="image"
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                textTransform:"uppercase",
                                textDecoration:"none",
                                textAlign:"start",
                                fontFamily:"Poppins",
                                marginTop:"6%",
                                color:"#003349",

                                fontWeight:"500",
                                }} >EARRINGS</Typography>
                            </Box>
                        </Link>
                       
                        </SwiperSlide>
                        <SwiperSlide>
                        <Link to = "/bangles" style={{textDecoration:"none"}} >
                        <Box sx={{ width: "100%", height: "80%",backgroundColor:"white" }}>
                                <LazyLoadImage
                                    src={BannerImage}
                                    alt="Indian Collection One"
                                    effect="blur"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
                                    className="image"
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                               textTransform:"uppercase",
                                textDecoration:"none",
                                textAlign:"start",
                                fontFamily:"Poppins",
                                marginTop:"6%",
                                color:"#003349",

                                fontWeight:"500",
                                }} >Bangles and bracelets</Typography>
                            </Box>
                        </Link>
                            
                        </SwiperSlide>
                        {/* <SwiperSlide>
                        <Link to="/pendants" style={{textDecoration:"none"}} >
                        <Box sx={{ width: "100%", height: "80%",backgroundColor:"white" }}>
                        <LazyLoadImage
                                    src={ImageTwo}
                                    alt="Indian Collection One"
                                    effect="blur"
                                    width="100%"
                                    height="100%"
                                    style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
                                    className="image"
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                textTransform:"uppercase",
                                textDecoration:"none",
                                textAlign:"center",
                                fontFamily:"Epilogue",
                                marginTop:"6%",
                                color:"rgba(0, 0, 0, 1)",
                                }} >Pendants</Typography>
                            </Box>
                            </Link>
                        </SwiperSlide> */}
                    </Swiper>
                </Box>
            </Box>
            <style jsx global>{`
                .swiper-pagination-bullet {
                    background-color: grey !important;
                }
                .swiper-pagination-bullet-active {
                    background-color: rgba(0, 51, 73, 1) !important;
                }
            `}</style>
        </>
    );
}

export default OucollectionSlider;
