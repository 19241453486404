import { Box } from '@mui/material';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageOne from '../../assets/HomePageBanner/Rectangle 9.webp';
import ImageTwo from '../../assets/HomePageBanner/Rectangle 83.webp';

const HomeTwoImages = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: {xs:"55rem",sm:"125vh",xxl:'90vh'},
        display: 'flex',
        flexDirection:{sm:"row",xs:"column"},
        position:"absolute",
        marginTop:{xs:"0%",sm:"2%"},
        top:"0",
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2%',
      }}
    >
      <Box
        sx={{
          width: {sm:"44%",xs:"90%"},
          height: '90%',
          marginTop:{xs:"5%",sm:"0%"},
          overflow: 'hidden',
          position: 'relative',
          '&:hover .image': {
            transform: 'scale(1.1)',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <LazyLoadImage
            src={ImageOne}
            alt="Indian Collection One"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: {sm:"44%",xs:"90%"},
          height: '90%',
          overflow: 'hidden',
          position: 'relative',
          '&:hover .image': {
            transform: 'scale(1.1)',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <LazyLoadImage
            src={ImageTwo}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', transition: 'transform 0.8s ease-in-out' }}
            className="image"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeTwoImages;
