import { Box, Typography } from '@mui/material'
import React from 'react'
import GetInspired from '../../assets/SideBannerImage/PendantsImage.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'



const PendantsBanner = () => {
  return (
    <Box  sx={{
        width:"100%",
        height:{xs:"85vh",sm:"80vh"},
        display:"flex",
        justifyContent:"center",
        backgroundColor:"#E8F5FF",
    }} >

    <Box sx={{
        width:"100%",
        height:"80%",
        display:"flex",
        flexDirection:{xs:"column-reverse",sm:"row"},
        marginTop:"2%",
    }} >


<Box sx={{
    width:{xs:"100%",sm:"50%"},
    height:{xs:"40%",sm:"100%"},
    padding:{xs:"6%",sm:"3%"},
}} >

<Typography  sx={{fontFamily:"Epilogue",fontSize:"16px",fontWeight:"500",marginBottom:"3%",marginTop:{xs:"5%",sm:"0%",letterSpacing:"2px"} }} >GET INSPIRED</Typography>
<Typography  sx={{color:"#003349",fontFamily:"Larose",marginBottom:"3%",fontSize:{xs:"26px",sm:"32px"}}} >OUR ICONIC CREATIONS</Typography>
<Typography sx={{width:{xs:"95%",sm:"85%"},fontFamily:"Epilogue",fontSize:"16px",fontWeight:"300",color:"#2C2C2C"}} >Step into a world where the beauty of the cosmos is captured in exquisite jewellery pieces. The Celestial jewellery by VEEKAY is an enchanting array of finely crafted adornments that evoke the mystery and majesty of the night sky.</Typography>

</Box>
<Box sx={{
    width:{xs:"100%",sm:"50%"},
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"100%",
}} >
<Box sx={{
    width:"100%",
    height:"100%",
    padding:{xs:"6%",sm:"0%"},
}} >
 <LazyLoadImage
        src={GetInspired}
        alt="Indian Collection Two"
        effect="blur"
        width="100%"
        height="100%"
        style={{ objectFit: 'cover'}}
      />
</Box>

</Box>

    </Box>
      
    </Box>
  )
}

export default PendantsBanner;
