import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import NavBanner from '../../components/NavBanner'
import ContactBanner from './ContactBanner'
import ContactFormBanner from './ContactFormBanner'
import OurLocationBanner from './OurLocationBanner'
import ContactMobileBanner from './ContactMobileBanner'
import ContactUsLocationweb from './ContactUsLocationweb'



const Contactus = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <Box>
    <NavBanner/>
    <ContactMobileBanner/>
    <ContactBanner/>
    <ContactFormBanner/>
    <ContactUsLocationweb/>
    <OurLocationBanner/>

    </Box>
  )
}

export default Contactus
