import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import NavBanner from '../../components/NavBanner'
import Necklacebanner from './Necklacebanner'
import Necklacebgbanner from './Necklacebgbanner'
import NecklaceNavigation from './NecklaceNavigation'
import Necklacebgmobilebaner from './Necklacebgmobilebaner'
import NecklaceMoreCollection from './NecklaceMoreCollection'
import NecklaceSection from './NecklaceSection'



const Necklace = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
  }, []);

  return (
    <Box>
      <NavBanner backgroundColor="#E8f5ff" />
      <Necklacebanner/>
      <Necklacebgmobilebaner/>
      <Necklacebgbanner/>
      <NecklaceSection/>
      <NecklaceNavigation/>
      <NecklaceMoreCollection/>
    </Box>
  )
}

export default Necklace
