import * as React from 'react';
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Link, useNavigate } from 'react-router-dom';
import Menu from '../../src/assets/Icons/Icon.png'
import { Box, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SideCancel from '../../src/assets/Icons/Group 3718.png'



function SideMenu() {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path);
      setOpen(false); // Close the drawer
    };

    
      
  return (
    <Box>
<Button onClick={() => setOpen(true)} sx={{ color: 'white' }}>
<img src={Menu} alt="" />
 </Button>

  <Drawer
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Slide} // Add Slide as TransitionComponent
        sx={{   width:"100%",
'& .MuiDrawer-paper': { transition: 'width 1s ease' },

 }} // Smooth transition for drawer width
      >
      
          <Box 
          sx={{
            width:"360px",
            display:"flex",
            flexDirection:"column",
            padding:"10%",
            height:"100%",
            background: "linear-gradient(to left, rgba(225, 237, 255, 1), rgba(252, 253, 255, 1))", // Adjust the gradient as needed

          }}
           >

           <Box 
           sx={{
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            width:"100%",
            background: "linear-gradient(to left, rgba(225, 237, 255, 1), rgba(252, 253, 255, 1))", // Adjust the gradient as needed


           }}
           
            >
            <Box>
            <Typography sx={{
                fontSize:"22px",
                fontWeight:"600",
                color:"rgba(0, 0, 0, 0.43)",
                marginTop:"10%",
            }} ></Typography>

            </Box>



            <Box sx={{
            width:"16%",
            height:"85%",
            
           }} 
           onClick={() => setOpen(false)}
           
           >
           <LazyLoadImage 
      src={SideCancel} 
      effect="blur"
          width="100%"
          height="100%"
       />
           </Box>

           </Box>
           
<Box 

sx={{
    width:"100%",
    display:"flex",
    flexDirection:"column",
    marginTop:"20%",
}}
>
 <Typography sx={{
                fontSize:"24px",
                fontWeight:"400",
                textTransform:"uppercase",
                fontFamily:"Epilogue",
                color:"rgba(0, 0, 0, 0.43)",
                marginBottom:"10%",
            }} >Menu</Typography>
<Link  style={{textDecoration:"none" ,color:"rgba(0, 0, 0, 1)",marginBottom:"15%",fontSize:"25px",fontWeight:"700",fontFamily:"Epilogue" }}   to="/" onClick={() => handleNavigation("/")} > Home</Link>
        <Link  style={{textDecoration:"none" ,color:"rgba(0, 0, 0, 1)",marginBottom:"15%",fontSize:"25px",fontWeight:"700",fontFamily:"Epilogue"  }}  to="/aboutus" onClick={() => handleNavigation("/shop")}>About us</Link>
        <Link style={{textDecoration:"none" ,color:"rgba(0, 0, 0, 1)",marginBottom:"15%",fontSize:"25px",fontWeight:"700",fontFamily:"Epilogue"  }}   to="/getinspired" onClick={() => handleNavigation("/catalogues")} > Get Inspired</Link>
        <Link style={{textDecoration:"none" ,color:"rgba(0, 0, 0, 1)",marginBottom:"15%",fontSize:"25px",fontWeight:"700",fontFamily:"Epilogue"  }}   to="/contactus"  onClick={() => handleNavigation("/Wishlist")} > Contact Us </Link>
        <Link  style={{textDecoration:"none" ,color:"rgba(0, 0, 0, 1)",marginBottom:"15%",fontSize:"25px",fontWeight:"700",fontFamily:"Epilogue"  }} to="/events" onClick={() => handleNavigation("/chat")}>Events</Link>
      

</Box>
         
        

        </Box>
       
         
          
        
          
        
      </Drawer>   
  </Box>
  )
}

export default SideMenu
