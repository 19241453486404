import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import NavBanner from '../../components/NavBanner'
import Banglebanner from './Banglebanner'
import BangleNavigation from './BangleNavigation'
import BangleMoreCollection from './BangleMoreCollection'
import Banglebgbanner from './Banglebgbanner'
import BangleSection from './BangleSection'

import BanglebgMobile from './BanglesbgMobile'

const Bangles = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
      }, []);

  return (
    <Box>
        <NavBanner backgroundColor="#E8F5FF" />
        <Banglebanner/>
        <Banglebgbanner/>
<BanglebgMobile/>
        <BangleSection/>
        <BangleNavigation/>
        <BangleMoreCollection/>
    </Box>
  )
}

export default Bangles
