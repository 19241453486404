import { Box } from '@mui/material'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Image from '../../assets/Images/Contactusimage.svg'


const ContactUsLocationweb = () => {
  return (
    <Box sx={{
        width:"100%",
        height:"100vh",
        display:{xs:"none",sm:"flex"},
        marginBottom:"6%",
    }}>
    <Box 
    sx={{
        width:"100%",
        height:"100%",
    }}
     >
    <LazyLoadImage
      src={Image}
      alt="Indian Collection Two"
      effect="blur"
      width="100%"
      height="100%"
      
    />
    </Box>
     
    </Box>
  )
}

export default ContactUsLocationweb
