import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import Image from '../../assets/ContactPage/ContactusMainFormImage.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'



const ContactFormBanner = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"120vh",sm:"110vh"},

        backgroundColor:"white",
        display:"flex",
        alignItems:"center",
    }} >
      <Box sx={{
        width:"100%",
        height:{xs:"110vh",sm:"70vh"},
        flexDirection:{xs:"column-reverse",sm:"row"},
        display:"flex"
      }} >
      <Box sx={{
        width:{xs:"100%",sm:"40%"},
        height:"100%",
      }} >
       <LazyLoadImage
            src={Image}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

      </Box>
      <Box sx={{
         width:{xs:"100%",sm:"60%"},
         height:"100%",
         display:"flex",
         justifyContent:"center",
         alignItems:"center",
      }} >
      <Box sx={{
        width:"90%",
        height:{xs:"100%",height:"90%"},
      }} >
      <Box sx={{
        width:{xs:"100%",sm:"75%"},

      }} >
      <Typography variant='h2' sx={{marginTop:{xs:"0%",sm:"5%"}, fontFamily:"Larose",textTransform:"uppercase",fontSize:{xs:"32px",sm:"20px"},marginBottom:"2%"}} >veekay at your service</Typography>
      <Typography variant='body' sx={{fontFamily:"Epilogue",textTransform:"uppercase",fontWeight:"300",color:"#003339",fontSize:{xs:"13px",sm:"12px"},lineHeight:"25px"}} >If you need any support, our team of experts is available to help answer all your questions: from assistance with your orders to style advice and gift ideas. Feel free to contact us, we are here for you</Typography>

        <Box sx={{
      display: "flex",
      flexDirection: "column",
    }}>
      <TextField 
        id="outlined-basic" 
        variant="outlined" 
        placeholder="FULL NAME" 
        sx={{
          marginTop: "3%",
          width: {xs:"100%",sm:"60%"},
          '& .MuiOutlinedInput-root': {
            height: '40px', // Adjust the height as needed
            borderRadius:"0%",
            '& input': {
              padding: '10px', // Adjust the padding as needed
            },
          }, '& .MuiInputBase-input::placeholder': {
        fontFamily: 'Epilogue, sans-serif', // Apply the Epilogue font
      },
    }}
    inputProps={{
      style: {
        fontFamily: 'Epilogue, sans-serif', // Apply the Epilogue font to the input text
      },
    }} 
      />
      <TextField 
        id="outlined-basic" 
        variant="outlined" 
        placeholder="EMAIL"  
        sx={{
          marginTop: "3%",
          width: {xs:"100%",sm:"60%"},
          '& .MuiOutlinedInput-root': {
            height: '40px', // Adjust the height as needed
            borderRadius:"0%",
            '& input': {
              padding: '10px', // Adjust the padding as needed
            },
          }, '& .MuiInputBase-input::placeholder': {
        fontFamily: 'Epilogue, sans-serif', // Apply the Epilogue font
      },
    }}
    inputProps={{
      style: {
        fontFamily: 'Epilogue, sans-serif', // Apply the Epilogue font to the input text
      },
    }} 
      />
    </Box>
<Button sx={{
          width: {xs:"100%",sm:"40%"},
          height:"45px",
    color:"white",
    fontSize:"16px",
    marginTop:"3%",
    fontWeight:"300",
    borderRadius:"0",
    fontFamily:"Epilogue",
    textTransform:"uppercase",
    backgroundColor:"rgba(54, 54, 54, 1)",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
        backgroundColor:"rgba(54, 54, 54, 1)",
        boxShadow: 'none', // Remove shadow

            }
    
    }}>Submit</Button>
       


      </Box>

      </Box>

      </Box>

      </Box>
    </Box>
  )
}

export default ContactFormBanner
