import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import ImageTwo from '../../assets/HomePageBanner/iris_16x9_OK 3.png'
import ImageOne from '../../assets/HomePageBanner/iris_16x9_OK 4.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'



const OurNewest = () => {
  return (
    <Box sx={{
        width:"100%",
        height:{xs:"38rem",sm:"36rem",xxl:'40rem'},
        position:"absolute",
        bottom:{xs:"3%",sm:"0%"},
        display:"flex",
        justifyContent:"center",
        backgroundColor:"#E8f5ff",
        alignItems:"center",
    }} >
    <Box sx={{
        width:"90%",
        height:"80%",
        display:"flex",
        justifyContent:"space-between",
        flexDirection:{xs:"column-reverse",sm:"row"},
        marginTop:"2rem",

    }} >
<Box sx={{
    width:{xs:"100%",sm:"32%"},
    height:{xs:"50%",sm:"100%"},
    display:"flex",
    flexDirection:"column",
    alignItems: { xs: "center", sm: "start" } ,// Align items horizontally,
    textAlign:"center",

}} >
<Typography sx={{color:"rgba(0, 51, 73, 1)",fontFamily:"Larose",textTransform:"uppercase",fontSize:{xs:"28px",sm:"1.8rem"},marginBottom:"8%",marginTop:{xs:"10%",sm:"20%"},textAlign:{xs:"center",sm:"center"}}} >Our Newest High Jewelry Collection</Typography>
<Typography   sx={{fontFamily:"Epilogue",width:{xs:"100%",sm:"90%"},marginBottom:{xs:"-5%",sm:"0%"}, fontSize:"1.1rem",color:"rgba(44, 44, 44, 1)",fontWeight:"300"}} >The "Eternal Radiance" collection draws inspiration from the timeless beauty and brilliance of natural elements, blending classic elegance with contemporary sophistication. </Typography>
{/* <Button sx={{
    width:"80%",
    height:"45px",
    color:"black",
    fontSize:"12px",
    textTransform:"uppercase",
    fontFamily:"Epilogue",
    marginTop:"12%",
    marginBottom:{xs:"10%",sm:"0%"},
    fontWeight:"400",
    borderRadius:"0",
    border:"1px solid rgba(0, 0, 0, 0.21)",
    '&:hover': {
        backgroundColor:"transparent",
        boxShadow: 'none', // Remove shadow

            }
    
    }}>Explore the Jewellery</Button> */}
</Box>
<Box sx={{display:"flex",width:{xs:"100%",sm:"64%"},  height:{xs:"50%",sm:"90%"}, justifyContent:"space-between"}} >
<Box sx={{
    width:{xs:"100%",sm:"49%"},
    height:"100%",
    marginBottom:{xs:"5%",sm:"0%"}
}} >
<Box sx={{
    width:"100%",
    height:"100%",
    padding:{xs:"0% 2% 0% 2%",sm:"0%"},
}} >
 <LazyLoadImage
            src={ImageTwo}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

</Box>

</Box>
<Box sx={{
    width:{xs:"100%",sm:"49%"},
    height:"100%",
}} >
<Box sx={{
    width:"100%",
    height:"100%",
    padding:{xs:"0% 2% 0% 2%",sm:"0%"},

}} >
 <LazyLoadImage
            src={ImageOne}
            alt="Indian Collection Two"
            effect="blur"
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          />

</Box>

</Box>
</Box>

    </Box>
      
    </Box>
  )
}

export default OurNewest
